import { useState } from 'react'
import { useStore } from '../../../../../mobx-store/context'
import { Name, Text, TopLine, Wrap, ShowMore } from './style'
import Moment from 'react-moment'
import parse from 'html-react-parser'

const Item = ({ data, isReportSource, isLicenseSource, valueName, type, onOpenEditModal, disabled }) => {
  const store = useStore()
  const [showFullText, setShowFullText] = useState(false);
  const maxLength = 250;

  const text = valueName ? data[valueName] || '' : data.details_html || '';
  const truncatedText = !text ? '' : text && text.length > maxLength
    ? `${text.slice(0, maxLength)}...`
    : text;

  const toggleTextDisplay = () => {
    setShowFullText(!showFullText);
  };

  const modifiedBy = (isLicenseSource)
    ? (type === 'details' ? data?.details_modified_by : data?.assessment_modified_by)
    : data?.modified_by;

  return (
    <Wrap>
      <TopLine>
        <Name>{store.getMember(modifiedBy)}</Name>
        <Moment format={'lll'}>{type === 'details' ? data?.details_modified_at : data?.assessment_modified_at}</Moment>
        {modifiedBy === store.user[0].id && <ShowMore onClick={() => onOpenEditModal(data.id, text)} style={{ marginLeft: 5 }}>(Edit)</ShowMore>}
      </TopLine>
      <Text dangerouslySetInnerHTML={{ __html: showFullText ? text || '' : truncatedText }} />
      {text.length > maxLength && (
        <ShowMore onClick={toggleTextDisplay}>
          {showFullText ? `Hide` : `Show Full Comment`}
        </ShowMore>
      )}
    </Wrap>
  )
}

export default Item
