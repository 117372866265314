import { Select } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import Input from '../../../components/common/Input'
import { getDescription, getJurisdictionCode } from '../../../helpers/helpers'
import List from '../components/List'
import { Item } from '../components/List/style'
import { Text } from '../components/style'

const Left = observer(({ params, store, active, setActive }) => {
  const history = useHistory()
  const { control } = useForm()

  const currentGuideName = store.resourcesCurrentTopic?.name

  const isGuides = params.type === 'guides'

  const hasJurisdictionContent = (currentItem) => {
    const info = currentItem.row_data.custom_fields_data.find(
      (item) => item.code === params.code
    )

    return (
      info?.detail &&
      info?.detail !== 'Not Specified' &&
      info?.summary &&
      info?.summary !== 'Not Specified'
    )
  }
  const hasSummary = (currentItem) => {
    const row = store.resourcesCurrentTopic?.rows?.find(item => item.id === store.rowId)
    const info = row?.row_data?.custom_fields_data?.find(item => item.code === currentItem.code)

    return (
      info?.detail &&
      info?.detail !== 'Not Specified' &&
      info?.summary &&
      info?.summary !== 'Not Specified'
    )
  }

  const getSummary = (currentItem) => {
    const row = store.resourcesCurrentTopic?.rows?.find(item => item.id === store.rowId)
    const info = row?.row_data?.custom_fields_data?.find(item => item.code === currentItem.code)

    return (
      info?.summary || 'No'
    )
  }

  const onClick = () => store.getResourceTopic(params.itemId)

  const onChange = (id) => {
    if (isGuides) {
      store.getResourceTopic(id)
      history.push(
        `/resource/guides-jurisdictions-list/${params.type}/null/${id}`
      )
    } else {
      const jurId = store.resourcesCurrentTopic.rows.find(
        (item) => item.id === id
      )?.jurisdiction
      store.setSingleValue('currentJurId', jurId)
      store.setSingleValue('rowId', id)
      store.getJurisdiction(jurId)
      history.push(
        `/resource/details/${params.code}/${params.itemId}/${params.selectId}/${params.type}/${id}`
      )
    }
  }

  useEffect(() => {
    if (!store.resourcesCurrentTopic)
      store.getResourceTopic(params.itemId, null, () => {
        history.push('/resource/table/guides')
      })
  }, [])


  useEffect(() => {
    if (!store.currentJurId)
      history.push('/resource/table/guides')
  }, [])

  useEffect(() => {
    if (store.resourcesCurrentTopic)
      if (
        !store.resourcesCurrentTopic?.rows.find(
          (item) => +item.id === +params.jursId
        )
      ) {
        // history.push('/resource/table/guides')
        return
      }
  }, [store.resourcesCurrentTopic])

  return (
    <div>
      <Input
        name={'topicJurs'}
        control={control}
        type={'select'}
        options={isGuides ? store.resources : store.jurs}
        customOptions={
          isGuides
            ? null
            : store.resourcesCurrentTopic?.rows.map((item) => (
              <Select.Option value={item.id}>
                {getDescription(store?.legislative_options?.all_jurisdictions, item.jurisdiction)}
              </Select.Option>
            ))
        }
        label={`Select a ${isGuides ? 'ReSource Guide' : 'Jurisdiction'}`}
        defaultValue={isGuides ? +params.selectId : +params.jursId}
        handleChange={onChange}
      />
      <Text style={{ margin: '10px 0' }}>Select a Requirement</Text>
      {active && (
        <List
          details={true}
          currentGuideName={currentGuideName || 'Guides'}
          options={
            isGuides
              ? store.resourcesCurrentTopic?.rows?.map((item) => (
                <Item
                  key={item.id}
                  cursor={item.requirements}
                  active={item.id === active?.id}
                  id={item.id === active?.id && 'active-item'}
                  onClick={() => {
                    if (item.requirements && hasJurisdictionContent(item)) {
                      store.setSingleValue('currentJurId', item.jurisdiction)
                      setActive(item)
                      history.push(
                        `/resource/details/${params.code}/${item.id}/${params.selectId}/${params.type}`
                      )
                    }
                  }}
                >
                  {getJurisdictionCode(item.jurisdiction, store, 1)}
                  <span
                    style={{
                      color:
                        (!item.requirements ||
                          !hasJurisdictionContent(item)) &&
                        '#bbb',
                      width: 80,
                      textAlign: 'center',
                    }}
                  >
                    {item.requirements && hasJurisdictionContent(item)
                      ? 'Yes'
                      : 'No'}
                  </span>
                </Item>
              ))
              : store.resourcesCurrentTopic?.guide_data?.custom_fields.map(
                (item) => (
                  <Item
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr',
                    }}
                    key={item.code}
                    active={item.code === params.code}
                    onClick={() => {
                      history.push(
                        `/resource/details/${item.code}/${store.resourcesCurrentTopic.id}/${params.selectId}/${params.type}/${store.rowId}`
                      )
                    }}
                  >
                    {item.name}
                    <span
                      style={{
                        width: 200,
                        marginLeft: 15,
                      }}
                    >
                      {getSummary(item).length > 60 ? getSummary(item).substring(0, 60) + '...' : getSummary(item)}
                    </span>
                  </Item>
                )
              )
          }
          active={active}
          setActive={(item) => setActive(item)}
          customOptions
          handleClick={onClick}
          page='details'
        />
      )}
    </div>
  )
})

export default Left
