import { useState } from 'react';
import ModalWrap from '../../../../../components/common/ModalNew';
import Button from '../../../../../components/common/Button/button'
import TextEditor from '../../HeldAdminItemAddModal/TextEditor';
import { Label } from '../../../../../components/common/Input/input.style'
import { Cancel } from '../../Tasks/Modal/style'
const EditNoteModal = ({
  onClose,
  onEdit,
  currentNoteId,
  onRemoveCurrentNote,
  initialText,
  edit = true,
}) => {
  const [details, setDetails] = useState(edit ? initialText : '')
  const [isEmpty, setIsEmpty] = useState(false)
  return (
    <ModalWrap onClose={onClose}>
      <Label>Edit Note</Label>
      <TextEditor
        title={'Summary'}
        updateDetails={setDetails}
        placeholderText={`Type new note here`}
        initialText={initialText}
        edit={true}
        setIsEmpty={setIsEmpty}
      />
      <div style={{ display: 'flex', alignItems: 'center', marginTop: 30 }}>
        {<Cancel style={{ color: 'var(--red)' }} onClick={() => onRemoveCurrentNote(currentNoteId)}>Delete Note</Cancel>}
        <Button
          disabled={isEmpty}
          text={'Save'}
          style={{ marginLeft: 'auto' }}
          onClick={() => onEdit(details, currentNoteId)}
        />
      </div>
    </ModalWrap>
  );
};

export default EditNoteModal;