import React, { useState } from 'react';
import Button from '../Button/button';
import expandIcon from '../../../assets/images/expand.svg';
import hideIcon from '../../../assets/images/hide.svg';
import expandIconWhite from '../../../assets/images/expand_white.svg';
import hideIconWhite from '../../../assets/images/hide_white.svg';

const ExpandableBlock = ({
  title = '',
  buttonText = '',
  children,
  buttonStyle = {},
  titleStyle = {},
  containerStyle = {},
  onExpand = () => { },
  onCollapse = () => { },
  whiteIcons = false,
}) => {
  const [expanded, setExpanded] = useState(true);

  const toggleExpanded = () => {
    setExpanded(!expanded);
    expanded ? onCollapse() : onExpand();
  };

  return (
    <div style={{ padding: 0, cursor: 'pointer', marginTop: 0, marginBottom: 10, ...containerStyle }}>
      <div onClick={toggleExpanded} style={{ display: 'flex', alignItems: 'center', fontFamily: 'var(--bold)', fontSize: 14, ...titleStyle }}>
        <Button
          text={buttonText}
          style={{
            fontSize: 14,
            backgroundColor: '#fff',
            border: 'none',
            padding: 0,
            fontFamily: 'var(--bold)',
            ...buttonStyle
          }}
          onClick={toggleExpanded}
          icon={whiteIcons ? (expanded ? hideIconWhite : expandIconWhite) : (expanded ? hideIcon : expandIcon)}
        />
        {title}
      </div>
      {expanded && <div style={{ marginTop: 10 }}>{children}</div>}
    </div>
  );
};

export default ExpandableBlock;