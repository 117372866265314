import React, { useEffect, useState } from 'react';
import { api } from '../../../../../api';
import Input from "../../../../../components/common/Input";
import { useForm } from 'react-hook-form';
import { ItemWrap, ItemText } from './style';
import GetReadyModal from './Modal';
import ButtonOrange from "../../../../../components/common/ButtonOrange";

function GetReadyList({ initialTasks, reportId, title, isReportSource = true }) {
  const [tasks, setTasks] = useState(initialTasks || []);
  const [allowBlur, setAllowBlur] = useState(false)
  const [allowTitleBlur, setAllowTitleBlur] = useState(false)
  const [currentTitle, setCurrentTitle] = useState(title || '')
  const { control, getValues } = useForm();
  const [visibleAddNew, setVisibleAddNew] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [currentTask, setCurrentTask] = useState(null)

  const createNewTask = (reportId, isReportSource) => {
    const url = isReportSource
      ? `/cms/report/${reportId}/create_get_ready_checkbox/`
      : `/cms/legislatives/${reportId}/set_applies_to/`
    api(url, {}, 'POST').then((res) => {
      isReportSource ? setTasks([...res.checkboxes]) : setTasks([...res])
    });
  }

  const updateTasks = (id, text, isReportSource, setTasks) => {
    const url = isReportSource ?
      `/cms/get_ready_checkboxes/${id}/update_checkbox/`
      : `/cms/applies_to/${id}/`
    api(url, { text }, 'PATCH').then((res) => {
      isReportSource ? setTasks([...res.checkboxes]) : setTasks([...res])

    });
  }

  const handleTaskChange = async (id, newText) => {
    const updatedTasks = tasks.map(task =>
      task.id === id ? { ...task, text: newText } : task
    );
    setTasks(updatedTasks);
    updateTasks(id, newText);
  };

  const handleTitleChange = (newTitle) => {
    api(`/cms/report/${reportId}/set_get_ready_checkbox/`, { get_ready_checkboxes_title: newTitle }, 'POST')
  }

  const onBlur = (id, value) => {
    const url = isReportSource
      ? `/cms/report/${reportId}/set_get_ready_checkbox_activity/`
      : `/cms/legislatives/${reportId}/set_applies_to_activity/`
    api(url, { id, text: value }, 'POST');
  }

  // useEffect(() => {
  //   tasks.length === 0 && createNewTask();
  // }, [tasks]);

  const getCurentTaskText = (id) => {
    return tasks.find(task => task.id === id).text
  }

  return (
    <div>
      {visibleAddNew && <GetReadyModal
        onClose={() => {
          setVisibleAddNew(false)
          setCurrentTask(null)
        }}
        reportId={reportId}
        editMode={false}
        setTasks={setTasks}
        currentTask={currentTask}
        isReportSource={isReportSource} />}
      {visibleEdit && <GetReadyModal
        onClose={() => {
          setVisibleEdit(false)
          setCurrentTask(null)
        }}
        reportId={reportId}
        editMode={true}
        setTasks={setTasks}
        currentTask={currentTask}
        isReportSource={isReportSource} />}
      {isReportSource &&
        <Input
          style={{ width: '50%', marginBottom: 30 }}
          control={control}
          smallLabel
          name={'get_ready_checkboxes_title'}
          label={'Checklist Title'}
          placeholder={'Set Title'}
          type={'text'}
          defaultValue={currentTitle}
          handleChange={(e) => setCurrentTitle(e.target.value)}
          onFocus={(e) => setAllowTitleBlur(true)}
          handleBlur={(e) => { allowTitleBlur && handleTitleChange(currentTitle) }}
        />
      }
      <div
        style={{
          color: 'var(--text)',
          fontFamily: 'var(--medium)',
          fontSize: 14,
        }}
      >
        {isReportSource ? 'Checklist Checkboxes' : 'Applies To'}
      </div>
      {tasks.map(task => (
        <ItemWrap>
          <ItemText>
            {task.text}
          </ItemText>
          <div>
            <ButtonOrange
              style={{
                fontFamily: 'var(--regular)',
                fontSize: 14,
                textDecoration: 'underline',
                width: 'fit-content',
                color: '#DF7411',
                // marginTop: 15
              }}
              onClick={() => {
                setVisibleEdit(true)
                setCurrentTask(task)
              }}
              text={`Edit`}
            />
          </div>
        </ItemWrap>
      ))}
      <div style={{
        color: 'var(--blue)',
        marginTop: 20,
        fontFamily: 'var(--bold)',
        fontSize: 12,
        cursor: 'pointer',
        textDecoration: 'underline',
      }} onClick={setVisibleAddNew}> {isReportSource ? 'Add new checkbox' : 'Add New'}</div>
    </div>
  );
}



export default GetReadyList;
