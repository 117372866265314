import React, { useState } from 'react';
import TaskItem from './item';
import Button from '../../../../../components/common/Button/button';
import expandIcon from '../../../../../assets/images/expand.svg';
import hideIcon from '../../../../../assets/images/hide.svg'
import { RowContainer } from './style';
import Moment from 'react-moment';

const DeficiencyBlock = ({ item, isReportSource, isLicenseSource, disabled, store, handleTaskClick, isCompleted, onClick, accessAllowed, onEditDeficiency, isIaClosed }) => {
  const [expanded, setExpanded] = useState(false);

  const { subject, tasks, deficiency_date, due_date, } = item;

  const disableEditDeficiency = store?.currentLegislative?.initial_application_status === 'ia_closed_approved' && store?.currentLegislative?.status === 'held'

  const isDeficiencyCompleted = !tasks.every(task => task.status === 'complete');

  const toggleExpanded = () => setExpanded(!expanded);

  return (
    <div style={{ padding: 0, marginTop: 0 }}>
      <RowContainer>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            text={`${deficiency_date}:`}
            style={{
              fontSize: 14,
              backgroundColor: '#fff',
              border: 'none',
              color: isDeficiencyCompleted ? 'var(--red)' : 'var(--blue)',
              padding: 0,
              marginLeft: 10,
              fontFamily: 'var(--bold)',
            }}
            onClick={toggleExpanded}
            icon={expanded ? hideIcon : expandIcon}
          />
          <div style={{
            fontFamily: 'var(--regular)',
            fontSize: 14,
            cursor: disableEditDeficiency ? 'auto' : 'pointer',
            marginLeft: 3,
            marginBottom: 3,
            color: isDeficiencyCompleted ? 'var(--red)' : 'var(--blue)',
            textDecoration: disableEditDeficiency ? 'none' : 'underline',
          }}
            onClick={() => {
              if (disableEditDeficiency) return;
              onEditDeficiency(() => store.setSingleValue('currentDeficiencyBlock', item))
            }}
          >
            {subject}
          </div>
        </div>
        {due_date && (
          <div style={{ fontSize: 14, padding: 5, fontFamily: 'var(--regular)', color: isDeficiencyCompleted ? 'var(--red)' : 'var(--text)', }} >
            <Moment format={'MM/DD/YYYY'}>{due_date}</Moment>
          </div>
        )}

      </RowContainer>
      {expanded && (
        <div style={{ marginTop: 10 }}>
          {tasks.map((item) => (
            <TaskItem
              key={item.id}
              item={item}
              isReportSource={isReportSource}
              isLicenseSource={isLicenseSource}
              disabled={disabled}
              accessAllowed={accessAllowed}
              onClick={() => onClick(item)}
              handleTaskClick={handleTaskClick}
              store={store}
              isCompleted={isCompleted}
              deficiencyData={item}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default DeficiencyBlock;