import React, { useEffect, useState } from 'react'
import Comment from './comment'
import { ContainerWrap } from './comment.style'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../mobx-store/context'
import { Spin } from 'antd'
import { toJS } from 'mobx'
import CustomPagination
  from '../../../../components/common/Pagination/pagination'
import { DocumentsPlaceholder } from '../right.style'
import { useParams } from 'react-router-dom/'
import { is } from 'immutable'

const CommentContainer = observer(({ grey,
  isReplied,
  legislative,
  isReportSource = false,
  isLicenseSource,
  isRenewalsReporting,
  reportId,
  getDataFromHistory = false,
  isNeedHistory = false,
  isTaskComments = false,
  taskId,
}) => {
  const store = useStore()
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(true)
  const params = useParams()

  useEffect(() => {
    if (legislative && !getDataFromHistory)
      store.loadComments(legislative, '1', () => setLoading(false), isReportSource, isLicenseSource, isRenewalsReporting, reportId, isNeedHistory, params.tabName, isTaskComments, taskId)
    else if (getDataFromHistory) setLoading(false)
  }, [legislative, store?.currentLegislative?.report?.id])

  if (loading) return <Spin />

  if (getDataFromHistory ? !store.assessmentComments : isTaskComments ? !store?.taskComments?.results : !store.comments.results) return null

  const comments = getDataFromHistory
    ? toJS(store?.assessmentComments)
    : isTaskComments ?
      store?.taskComments.results
      : store?.comments.results

  return (
    <ContainerWrap isTaskComments={isTaskComments}>
      {!comments.length &&
        <DocumentsPlaceholder
          style={{
            marginTop: 15,
            borderBottom: '1px solid #bbb',
            paddingBottom: 15,
          }}
        >
          There is no Messages associated with this item yet.
        </DocumentsPlaceholder>}

      {comments.map((item, index) => (
        <Comment
          item={item}
          legislative={legislative}
          parentId={item.id}
          grey={index % 2 === 0}
          isReportSource={isReportSource}
          isLicenseSource={isLicenseSource}
          isRenewalsReporting={isRenewalsReporting}
          getDataFromHistory={getDataFromHistory}
          disabled={false}
          isTaskComments={isTaskComments}
          taskId={taskId}
          params={params}
        />
      ))}
      <CustomPagination
        total={isTaskComments ? store?.taskComments?.count : store.comments.count}
        pageSize={'5'}
        current={page}
        onChange={(page) => {
          store.loadComments(legislative, page, null, isReportSource, isLicenseSource, isRenewalsReporting, reportId, isNeedHistory, params.tabName, isTaskComments, taskId)
          setPage(page)
        }}
      />
    </ContainerWrap>
  )
})

export default CommentContainer
