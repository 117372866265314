import styled from 'styled-components'
import { Wrap as ButtonOrange } from '../../../../../components/common/ButtonOrange/style'

export const Overlay = styled('div')`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 99998;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  pointer-events: ${(props) => (props.throughOverlay ? 'none' : 'all')};
`

export const Wrap = styled('div')`
  width: 650px;
  max-height: 95vh;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 50px 40px;
  background-color: #fff;
  border-radius: 10px;
  z-index: 99999;
  position: fixed;
  cursor: default;
  font-family: var(--regular);
  color: var(--text);
  font-size: 16px;

  ${ButtonOrange} {
    max-width: 500px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
  }

  span {
    font-family: var(--regular);
    font-size: 14px;
  }

  #close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 9999;
  }

  @media (max-width: 650px) {
    width: 90%;
  }

  iframe {
    width: 100%;
    height: 400px;
    border: 0;
  }
`

export const Title = styled('div')`
  font-family: var(--medium);
  font-size: 24px;
  margin-bottom: 20px;
`
export const Subtitle = styled('div')`
  font-family: var(--regular);
  font-size: 14px;
  overflow-wrap: break-word; /* Современный стандарт */
  word-wrap: break-word; /* Для совместимости со старыми браузерами */
  word-break: break-word;
`

export const Row = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  margin-bottom: 15px;
`

export const Cancel = styled('div')`
  font-family: var(--regular);
  text-decoration: underline;
  cursor: pointer;
`
export const Block = styled('div')`
  border-bottom: 1px solid ${(props) => (props.bordered ? '#C4CACC' : '#fff')};
`
