import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import {
  Redirect,
  Route,
  Router,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'
import { api } from '../../api'
import { checkRole } from '../../helpers/helpers'
import { useStore } from '../../mobx-store/context'
import AddMember from '../Auth/AddMember/addMember'
import Billing from '../Auth/Billing/billing'
import ResourceDetails from './Detail'
import ResourceOnHold from './ResourceOnHold'
import ResourceOptions from './ResourceOptions/index'
import ResourceTable from './ResourceTable'
import ResourceSearch from './ResourceSearch'
import SignUpForResource from './SignUpForResource'
import TopicsJursList from './TopicsJursList'
import ViewAcrossJuris from './ViewAcrossJuris'
import { userConfirmation } from '../../helpers/helpers'

const ResourceRoot = observer(() => {
  const { path } = useRouteMatch()
  const history = useHistory()
  const store = useStore()
  const company = store.company?.[0]
  const user = store.user?.[0]
  const resourceTariff = store.resourceTariffInfo

  const getTableSettings = () => {
    api('/user_settings/user_resource_columns/', {}, 'GET').then(data => {
      if (!data.erors) {
        store.setSingleValue('crossJursTebleSettings', data)
      }
    })
  }

  useEffect(() => {
    document.title = 'PBMSource - ReSource'
  }, []);

  useEffect(() => {
    if (!store.user.length) store.getUser()
    if (!store.resourceTariffInfo) store.getResourceTariffInfo()
    if (!store.crossJursTebleSettings) {
      getTableSettings()
    }
  }, [])
  // useEffect(() => {
  //   if (localStorage.getItem('logoutClicked') === 'true') {
  //     localStorage.setItem('logoutClicked', 'false')
  //     localStorage.setItem(
  //       'lastSessionPathname',
  //       pathname
  //     )
  //     history.push('/login');
  //     cookies.remove('pbm_token');
  //     store.reset();
  //     setLoading(false)
  //     setVisibleTimeout(false)
  //     setIsAuthenticated(false)
  //   }
  // }, [localStorage.getItem('logoutClicked')])

  if (user?.resource_role !== 'admin' && !company?.have_access_to_resource)
    return <ResourceOnHold />

  if (
    !company.have_access_to_resource &&
    resourceTariff?.payment_status === 'offline'
  )
    return <ResourceOnHold />

  if (
    !checkRole([store.user?.[0]?.resource_role], ['admin']) &&
    !store.company?.[0]?.have_access_to_resource
  )
    return <SignUpForResource />

  if (!store.resourceTariffInfo || !company || !user) return 'loading...'

  return (
    <Switch>
      <Router history={history}>
        <OnlyRegisteredRoute path={`${path}`} exact>
          <ResourceTable />
        </OnlyRegisteredRoute>
        <OnlyRegisteredRoute path={`${path}/word-search/`} exact>
          <ResourceSearch />
        </OnlyRegisteredRoute>
        <OnlyRegisteredRoute path={`${path}/table/:type?/:id?`}>
          <ResourceTable />
        </OnlyRegisteredRoute>
        <OnlyRegisteredRoute
          path={`${path}/guides-jurisdictions-list/:type/:id/:selectId/:jurisdictionID?`}
        >
          <TopicsJursList />
        </OnlyRegisteredRoute>
        <OnlyRegisteredRoute
          path={`${path}/across-jurisdiction/:code/:topicId/:selectId/:type`}
        >
          <ViewAcrossJuris />
        </OnlyRegisteredRoute>
        <OnlyRegisteredRoute
          path={`${path}/details/:code/:itemId/:selectId/:type/:jursId?`}
        >
          <ResourceDetails />
        </OnlyRegisteredRoute>
        <Route path={`${path}/options`}>
          <ResourceOptions />
        </Route>
        <DisableWhenRegisterPassed path={`${path}/addMembers`}>
          <AddMember isResource />
        </DisableWhenRegisterPassed>
        <OnlyIfRegisterStarted path={`${path}/billing`}>
          <Route path={`${path}/billing`}>
            <Billing isResource />
          </Route>
        </OnlyIfRegisterStarted>
      </Router>
    </Switch>
  )
})

export default ResourceRoot

const OnlyIfRegisterStarted = ({ children, path }) => {
  const store = useStore()
  const { pathname } = useLocation()
  const company = store.company?.[0]
  const isPathMatch = pathname === path

  useEffect(() => {
    if (!company) store.getCompany()
  }, [])

  if (
    isPathMatch &&
    !store.isResourceRegisterStarted &&
    company.is_resource_registration_passed
  )
    return <Redirect to='/edit-profile' />

  return children
}

const MemberWithNoRightsWrap = ({ children }) => {
  const store = useStore()
  const user = store.user?.[0]

  if (user?.member_type === 'member' && !user?.have_access_to_resource)
    return <Redirect to='/edit-profile' />

  return children
}

const DisableWhenRegisterPassed = ({ children, path }) => {
  const store = useStore()
  const { pathname } = useLocation()

  const company = store.company?.[0]
  const isPathMatch = pathname === path

  useEffect(() => {
    if (!company) store.getCompany()
  }, [])

  if (isPathMatch && company && company.is_resource_registration_passed)
    return <Redirect to='/edit-profile' />

  return (
    <MemberWithNoRightsWrap>
      <Route path={path}>{children}</Route>
    </MemberWithNoRightsWrap>
  )
}

const OnlyRegisteredRoute = ({ children, path, exact }) => {
  const store = useStore()
  const { pathname } = useLocation()

  const user = store.user?.[0]
  const company = store.company?.[0]

  if (
    (pathname === path || pathname === '/resource/table/guides') &&
    user &&
    company &&
    (!user?.have_access_to_resource || !company.have_access_to_resource)
  )
    return null

  return (
    <MemberWithNoRightsWrap>
      <Route path={path} exact={exact}>
        {children}
      </Route>
    </MemberWithNoRightsWrap>
  )
}
