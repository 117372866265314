export function formatCurrency(value) {

  if (typeof value === 'number') {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(value);
  }

  if (typeof value === 'string') {
    const parsed = parseFloat(value);
    const isNumeric = !isNaN(parsed) && isFinite(parsed);

    if (isNumeric) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(parsed);
    } else {
      return value;
    }
  }


  return 'No Data';
}