import styled from 'styled-components'
import { Text } from '../right.style'

export const Container = styled("div")`
  border-radius: 4px;
  margin-left: ${(props) =>
    props.isReplied ? "3.125em" : props.isReply ? "3em" : "0 "};
`;

export const ContainerWrap = styled("div")`
  position: relative;
  max-height: ${props => props.isTaskComments ? '30vh' : 'none'};
  overflow-y: ${props => props.isTaskComments ? 'auto' : 'none'};
  margin-bottom:${props => props.isTaskComments ? '0.2em' : '6em'};
  .ant-pagination {
    display: flex;
    align-items: center;
    margin-top: 2em;
  }
  .ant-pagination-item {
    border-color: #dadada !important;
    margin: 0 0.1em !important;
    border-radius: 4px;
    font-family: var(--regular);
    font-size: 0.714em;
    height: 15px !important;
    width: 16px !important;
    min-width: 16px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    &-active {
      a {
        font-family: var(--bold);
        color: var(--orange);
      }
    }
    a {
      height: 15px;
      line-height: 15px;
      &:hover {
        color: var(--orange);
      }
    }
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    display: none !important;
  }
`;

export const CommentAuthor = styled("span")`
  font-family: var(--medium) !important;
  color: var(--text);
`;

export const Mention = styled("span")`
  font-family: var(--medium);
  color: var(--orange);
`;

export const ReplyButton = styled("div")`
  display: flex;
  align-items: center;
  color: #7d8587;
  font-family: var(--regular);
  cursor: pointer;
  img {
    height: 0.7em;
    margin-right: 0.5em;
  }
`;

export const Wrap = styled("div")`
  background-color: ${(props) =>
    props.active ? "rgba(242, 161, 34, 0.1)" : props.grey ? "#f4f6f6" : "#fff"};
  padding: 0.5em 1em;
  margin-top: ${(props) => props.isReply && "1em"};
  margin-top: ${(props) => !props.isReplied && !props.isReply && "0.688em"};
  border-radius: 4px;
  font-size: ${(props) => props.isReplied && "0.75em"};
  font-style: ${(props) => props.isReplied && "italic"};
  position: relative;
  z-index: 9;
  ${Text} {
    opacity: ${(props) => props.isReplied && ".7"};
  }
  &:before {
    content: "";
    position: absolute;
    left: -20px;
    width: 5px;
    height: 130%;
    background-color: #e3e3e3;
    border-radius: 3px;
    top: ${(props) => (props.single || props.last ? "-80%" : 0)};
    z-index: -3;
    display: ${(props) => !props.isReply && "none"};
  }
  &:after {
    content: "";
    position: absolute;
    left: -20px;
    width: 20px;
    height: 5px;
    background-color: #e3e3e3;
    border-radius: 3px;
    top: 45%;
    display: ${(props) => !props.isReply && "none"};
  }
`;

export const CommentLeft = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 56px;
`;

export const ToggleButton = styled("div")`
  font-size: 10px;
  color: var(--orange);
  font-family: var(--medium);
  cursor: pointer;
`;

export const ReplyBlock = styled("form")`
  padding-left: 3em;
  padding-right: 1em;
  display: flex;
  position: relative;
  background-color: rgba(242, 161, 34, 0.1);
  padding-bottom: 1em;
  &:before {
    content: "";
    position: absolute;
    left: 1em;
    width: 1.8em;
    height: 5px;
    background-color: #e3e3e3;
    top: 60%;
    transform: translateY(-2.5px);
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  &:after {
    content: "";
    position: absolute;
    width: 5px;
    height: calc(50% + 15px);
    top: -5px;
    background-color: #e3e3e3;
    left: 1em;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
`;

export const To = styled("div")`
  font-size: 0.75em;
  font-family: var(--medium);
  font-style: italic;
  color: var(--orange);
`;

export const RepliesBlock = styled("div")`
  overflow: hidden;
`;
