import React from 'react';
import Moment from 'react-moment';
import ButtonOrange from '../../../../../components/common/ButtonOrange';
import { Item } from './style';
import { Badge } from 'antd';

const TaskItem = ({
  item,
  isReportSource,
  isLicenseSource,
  disabled,
  accessAllowed,
  onClick,
  handleTaskClick,
  store,
  isCompleted,
  deficiencyData,
}) => {

  return (
    <Item>
      <div>{store.getMember(item.assignee)}</div>
      {isReportSource || isLicenseSource ?
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {item.type === 'get_ready_task' && (
            <div style={{
              display: 'flex',
              alignItems: 'center',
              gap: '3px',
              fontFamily: 'var(--medium)',
              color: 'var(--blue)',
            }}>
              <span>Checklist Task:</span>
              {item.unread_messages_count > 0 && item.type === 'default' && (
                <Badge count={item.unread_messages_count} overflowCount={9} style={{ marginLeft: 3 }} />
              )}
            </div>
          )}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 3,
              cursor: 'pointer',
              maxWidth: 300,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            onClick={() => { onClick(item) }}
          >
            <span style={{ textDecoration: 'underline' }}>{
              item.description.includes(item.task_template)
                ? item.description
                : `${item.task_template ? item.task_template + ' ' : ''}${item.description}`
            }
            </span>
            {item.unread_messages_count > 0 && (
              <Badge count={item.unread_messages_count} overflowCount={9} style={{ marginLeft: 3 }} />
            )}
          </div>
        </div>
        : isLicenseSource ?
          disabled ?
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  maxWidth: 350,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                <span>{item.description}</span>
                {item.unread_messages_count > 0 && (
                  <Badge count={item.unread_messages_count} overflowCount={9} style={{ marginLeft: 3 }} />
                )}
              </div>
            </div>
            : <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  maxWidth: 350,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                onClick={() => { onClick(item) }}
              >
                <span>
                  {item.description}
                </span>
                {item.unread_messages_count > 0 && (
                  <Badge count={item.unread_messages_count} overflowCount={9} style={{ marginLeft: 3 }} />
                )}
              </div>
            </div>
          : <div style={{ display: 'flex', flexDirection: 'column' }}>
            {item.type !== 'default' && !isLicenseSource &&
              <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: '3px',
                fontFamily: 'var(--medium)',
                color: 'var(--blue)',
              }}>
                <span>Consideration Task:</span>
                {item.unread_messages_count > 0 && (
                  <Badge count={item.unread_messages_count} overflowCount={9} style={{ marginLeft: 3 }} />
                )}
              </div>
            }
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '3px',
                cursor: 'pointer',
                maxWidth: 350,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              onClick={() => { onClick(item) }}
            >
              <span style={{ textDecoration: 'underline' }}>
                {item.description.includes(item.task_template)
                  ? item.description
                  : `${item.task_template ? item.task_template + ' ' : ''}${item.description}`
                }
              </span>
              <span>
                {item.unread_messages_count > 0 && item.type === 'default' && (
                  <Badge count={item.unread_messages_count} overflowCount={9} style={{ marginLeft: 3 }} />
                )}
              </span>

            </div>
          </div>

      }
      <div style={{ paddingLeft: 20, fontSize: 14, fontFamily: 'var(--regular)' }}>
        <Moment format={'MM/DD/YYYY'}>{item.due_date}</Moment>
      </div>
      <div
        style={{
          textDecoration: item.status === 'complete' && 'underline',
          cursor: item.status === 'complete' && 'pointer',
        }}
      >
        {isCompleted(item) ? (
          <div
            style={{ fontFamily: 'var(--regular)', fontSize: '0.875em' }}
            onClick={() => handleTaskClick(item, true)}
          >
            Completed
          </div>
        ) : (
          disabled ? (
            <div style={{ fontFamily: 'var(--regular)', fontSize: '14px' }}>
              {item.status[0].toUpperCase() + item.status.slice(1)}
            </div>
          ) : (
            (accessAllowed || store.user?.[0]?.id === item.assignee) && (
              <ButtonOrange
                text={'Complete task'}
                onClick={() => handleTaskClick(item, false, deficiencyData)}
              />
            )
          )
        )}
      </div>
    </Item>
  );
};

export default TaskItem;