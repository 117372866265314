import styled from 'styled-components'

export const Wrap = styled('div')`
  width: 100%;
  //padding: 0 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
  border-bottom: 1px solid
    ${(p) => (p.isResource ? '#fff' : 'rgba(0, 0, 0, 0.2)')};
`

export const Title = styled('div')`
  font-family: var(--sf-d-bold);
  font-size: 0.875em;
  color: #000;
  margin-right: 0.75em;
`
export const WorkingFlow = styled('div')`
  margin-left: 20px;
  margin-bottom: 5px;
  font-size: 11px;
  font-family: var(--bold);
  color: var(--dark-grey);
`

export const Item = styled('div')`
  width: fit-content;
  height: 32px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${props => props.isTaskComments ? '185px' : '170px'};
  display: flex;
  align-items: center;
  padding: 0 0.875em;
  border-radius: 6px 6px 0 0;
  // border: 1px solid #C4CACC;
  box-shadow: ${(props) => props.active && '0px -2px 4px 1px rgba(0, 0, 0, 0.1)'};
  color: ${(props) => props.disabled ? ' var(--grey)' : ' var(--text)'};
  font-family: ${(props) =>
    props.active ? 'var(--semiBold)' : 'var(--regular)'};
  border-bottom: 0;
  cursor: pointer;
  justify-content: center;
  margin-right: ${props => props.name === 'Deficiency-tab' ? '20px' : '3px'};
  margin-left: ${props => props.name === 'Working-tab' ? '20px' : 'none'};
  background-color:${(props) =>
    props.disabled
      ? 'var(--light-grey)'
      : props.calendar
        ? '#FAEEE0'
        : props.myTasks
          ? '#91C03E99'
          : props.active
            ? '#FFFFFF'
            : 'var(--light-grey)'};
  @media (max-width: 1760px) {
    font-size: ${(props) => props.documentTabs && '0.75em'} ;
  }
`
