import { Checkbox } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { getDescription } from '../../../../../helpers/helpers'
import { useStore } from '../../../../../mobx-store/context'
import Modal from '../../Modal'
import { Cell, Grid, Guide, Price } from './style'
import GuideNameTooltip from '../../../../../components/common/GuideNameWithTooltip'

const Item = observer(
  ({
    item,
    primaryJurisdictions,
    onJurisdictionChange,
    totalSource,
    paidJurs,
    toggleItem,
    viewOnly,
    index,
    staticItems,
  }) => {
    const store = useStore()
    const { control, handleSubmit, errors } = useForm()
    const [visible, setVisible] = useState(false)
    const [customJurisdictions, setCustomJurisdictions] = useState([])

    const currentStaticItem = staticItems?.find(
      (staticItem) => staticItem.guide_id === item.guide_id
    )

    const onSave = (values) => {
      setVisible(false)
      setCustomJurisdictions(values.jurisdictions)
      onJurisdictionChange(item.id || item.guide_id, values.jurisdictions)
    }

    const onToggle = (e) => {
      if (!e.target.checked && item.status === 'paid') return
      toggleItem(item.id || item.guide_id, e.target.checked ? 'add' : 'remove')
    }

    useEffect(() => {
      const jurs = [
        ...(item.status !== 'on hold' ? item.jurisdictions || [] : []),
        ...(item.custom_no_paid_jurs || []),
      ]?.filter(
        (item) =>
          !primaryJurisdictions?.includes(item) && !paidJurs?.includes(item)
      )

      setCustomJurisdictions(jurs)
    }, [item.jurisdictions, primaryJurisdictions])

    return (
      <>
        {visible && (
          <Modal
            store={store}
            control={control}
            onSave={handleSubmit(onSave)}
            onClose={() => setVisible(false)}
            selectedJurisdictions={customJurisdictions}
            error={errors.jurisdictions}
            primaryJurisdictions={primaryJurisdictions}
            notAllowedToDeleteJurisdictions={currentStaticItem.jurisdictions}
            currentItem={item}
          />
        )}

        <Grid viewOnly={viewOnly} index={index}>
          {!viewOnly && (
            <Cell>
              <Checkbox
                onChange={(e) => onToggle(e)}
                checked={item.checked}
                disabled={item.disabled}
              />
            </Cell>
          )}

          {/* <Cell style={{ justifyContent: 'center' }}>
            <Price>${totalSource ? 9.999 : item.price}</Price>
          </Cell> */}
          <Cell style={{ marginLeft: 10 }}>
            <Guide>{totalSource ? 'All' :
              <GuideNameTooltip
                guideName={item.name || item.guide_name}
                description={item.description || item.guide_discription} />}
            </Guide>
          </Cell>
          <Cell>
            <Guide
              style={{ fontSize: 12 }}
              onClick={() => {
                !totalSource && !viewOnly && setVisible(true)
              }}
            >
              {!customJurisdictions?.length ? (
                '(0)'
              ) : (
                <>
                  ({customJurisdictions?.length}){' '}
                  {customJurisdictions?.map(
                    (item, index) =>
                      index < 3 && getDescription(store.resourceJurisdictions, item, 'code') &&
                      getDescription(store.resourceJurisdictions, item, 'code') + ' '
                  )}
                </>
              )}
            </Guide>
          </Cell>
          {/* <Cell style={{ justifyContent: 'center' }}>
            {viewOnly ? (
              <Guide style={{ textDecoration: 'none' }}>
                {item.line_of_business ? (
                  'Yes'
                ) : (
                  <Link to={'/resource/options'}>No(Add)</Link>
                )}
              </Guide>
            ) : (
              <Checkbox
                onChange={(e) => toggleLineOfBusiness(e)}
                checked={item.line_of_business}
                disabled={item.status === 'paid'}
              />
            )}
          </Cell> */}
        </Grid>
      </>
    )
  }
)

export default Item
