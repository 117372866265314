import { message } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useState, useEffect } from 'react'
import Moment from 'react-moment'
import { api } from '../../../../api'
import documentIcon from '../../../../assets/images/description_24px.svg'
import newIcon from '../../../../assets/images/New.svg'
import Button from '../../../../components/common/Button/button'
import ButtonOrange from '../../../../components/common/ButtonOrange'
import { getError } from '../../../../helpers/helpers'
import { useStore } from '../../../../mobx-store/context'
import { Text, Wrap } from './style'

const Item = observer(React.forwardRef(({ item, secondary, active, subscriptionAll, archive }, ref) => {
  const store = useStore()
  const [versions, setVersions] = useState([])
  const [loading, setLoading] = useState(false)
  const [subscription, setSubscription] = useState(item.subscribed)
  const [isRead, setIsRead] = useState(item.is_read)

  useEffect(() => {
    if (secondary) {
      setIsRead(true)
    }
  }, [])

  const toggleShow = () => {
    if (versions.length) return setVersions([])
    api(`/proposed_source/${item.id}/versions/${archive ? '?&archived=true' : ''}`, {}, 'GET').then((data) => {
      if (data.errors) return message.error(getError(data))

      setVersions(data.versions.filter((el, index) => index !== 0))
    })
  }
  const toggleAlert = () => {
    if (secondary) return
    setLoading(true)
    api(`/proposed_source/${item.id}/toggle_subscribe/${archive ? '?&archived=true' : ''}`, {}, 'POST').then(
      (data) => {
        if (!data.errors) {
          store.getBills()
        }
      }
    ).then(() => {
      setLoading(false)
      setSubscription(!subscription)
    })
  }

  const markAsRead = () => {
    if (item.is_read) return null
    api(`/proposed_source/${item.id}/set_read/${archive ? '?&archived=true' : ''}`, {}, 'POST').then(
      (data) => {
        if (!data.errors) {
          setIsRead(true)
        }
      }
    )
  }
  const chooseJur = (jur) => {
    const obj = store.proposed_source_jurisdictions.filter(item => item.id === jur)
    if (obj[0]?.code === 'federal') {
      return 'FD'
    } else if (obj[0]?.code === 'district_of_columbia') {
      return 'DC'
    }

    return obj[0]?.code || '--'
  }
  const itemBody = (
    <>
      <Wrap name={'row'} active={versions.length || active}>
        <Text isRead={isRead}
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: 5,

          }}
          as='a'
          target='_blank'
          href={item.file}
          onClick={() => markAsRead()}
        >
          {item.name} <div style={{ display: 'flex', margin: '0px 5px', minWidth: 50 }}>
            <img style={{ paddingRight: 5 }} src={documentIcon} alt='document' />
            {item.is_new && !isRead && <img src={newIcon} alt='newLabel' />}
          </div>
        </Text>
        <Text isRead={isRead} >{item.title}</Text>
        <Text isRead={isRead} dangerouslySetInnerHTML={{
          __html: item.notes,
        }} />
        <Text isRead={isRead} >{chooseJur(item.jurisdiction)}</Text>
        <Text isRead={isRead} >{item.status}</Text>
        <Text isRead={isRead} >
          <Moment format={'MM/D/YYYY'}>{item?.date}</Moment>
        </Text>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: 15,
          }}
        >
          {(!secondary && item.have_versions) && (
            <ButtonOrange
              text={versions.length ? 'Hide History' : 'Show History'}
              style={{ marginRight: 20, color: 'var(--text)' }}
              bold={!isRead}
              onClick={() => {
                toggleShow()
                markAsRead()
              }}
            />
          )}
          {(!archive && secondary && item.is_archived) && (
            <div style={{
              marginTop: 6,
              marginRight: 23,
              color: 'var(--red)',
              fontFamily: 'var(--bold)',
              fontSize: 14,
            }}>
              Archived
            </div>)}
          <Button
            text='Alert Me'
            style={{
              minWidth: 90,
              backgroundColor: subscription
                ? '#70B461'
                : secondary
                  ? '#C3CACC'
                  : '#969FA2',
              padding: '0 15px',
              cursor: secondary || subscriptionAll || archive ? 'not-allowed' : 'pointer',
            }}
            onClick={() => {
              if (subscriptionAll || archive || secondary) { return }
              toggleAlert()
              markAsRead()
            }}
            loading={loading}
          />
        </div>
      </Wrap>
      {versions.map((item) => (
        <Item key={item.id} item={item} active secondary />
      ))}
    </>
  )
  const content = ref
    ? <div ref={ref}>{itemBody}</div>
    : <div>{itemBody}</div>

  return content
}))

export default Item

