import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { getDescription } from '../../../../helpers/helpers'
import { useStore } from '../../../../mobx-store/context'
import Title from '../../components/Title'
import Button from '../../../../components/common/Button/button'
import expandIcon from '../../../../assets/images/expand.svg'
import hideIcon from '../../../../assets/images/hide.svg'
import Item from './Item'
import { Wrap } from './style'

const Right = observer(({ item }) => {
  const store = useStore()
  const params = useParams()
  const history = useHistory()
  const [activeAll, setActiveAll] = useState(null)

  const isGuides = params.type === 'guides'

  const items = store.resourcesCurrentTopic?.rows.filter(
    (item_curr) =>
      item_curr.jurisdiction ===
      (isGuides ? item.jurisdiction : +params.selectId)
  )

  const isMoreThanOne = items?.length > 1

  useEffect(() => {
    setActiveAll(null)
  }, [item, store.resourceFiltered])

  useEffect(() => {
    if (
      isGuides &&
      !store.resourcesCurrentTopic?.rows.find((item) => item.id === +params.id)
    )
      history.push('/resource/table/guides')
  }, [])

  const row = isGuides ? item : item.rows[0]

  if (!store.resourceFilters) return null

  return (
    <Wrap>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        <Title
          title={item.name || store.resourcesCurrentTopic?.name}
          jurisdiction={isGuides
            ? getDescription(store.resourceJurisdictions, row.jurisdiction)
            : getDescription(store.resourceJurisdictions, +params.selectId)}
          showButton
        />
        {isMoreThanOne &&
          <Button
            text={activeAll ? 'Hide All' : 'Expand All'}
            style={{
              backgroundColor: '#fff',
              border: '1px solid #455154',
              color: '#455154',
              padding: '0 15px',
            }}
            onClick={() => { setActiveAll(!activeAll) }}
            icon={activeAll ? hideIcon : expandIcon}
          />
        }
      </div>
      {items?.map((currItem) => (
        <Item
          item={currItem}
          topic
          activeAll={activeAll}
          isMoreThanOne={isMoreThanOne}
        />
      ))}
    </Wrap>
  )
})

export default Right
