import { useEffect, useState } from 'react';
import Modal from '../ModalNew';
import Button from '../Button/button';
import { Title, Description } from './style';

const TimeOutModal = ({ onLogout, onContinue }) => {
  const [minutesLeft, setMinutesLeft] = useState(15);

  useEffect(() => {
    const timer = setInterval(() => {
      setMinutesLeft((prevMinutes) => prevMinutes - 1);
    }, 60000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'continueClicked' && event.newValue === 'true') {
        onContinue();
      } else if (event.key === 'logoutClicked' && event.newValue === 'true') {
        onLogout();
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => window.removeEventListener('storage', handleStorageChange);
  }, [onLogout, onContinue]);

  const handleContinue = () => {
    localStorage.setItem('continueClicked', 'true');
    onContinue();
  };

  const handleLogout = () => {
    localStorage.setItem('logoutClicked', 'true');
    onLogout();
  };

  if (minutesLeft === 0) {
    handleLogout();
  }

  return (
    <Modal onClose={handleContinue}>
      <Title>You’ll be signed off in {minutesLeft} minutes</Title>
      <Description>
        We will sign you out for your security and protection of sensitive data.
      </Description>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <Button text="Sign Me Out" style={{ marginTop: 50 }} onClick={handleLogout} />
        <Button text="I’m Still Working" style={{ marginTop: 50 }} onClick={handleContinue} />
      </div>
    </Modal>
  );
};

export default TimeOutModal;
