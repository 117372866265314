import { text } from '@fortawesome/fontawesome-svg-core';
import React, { useRef, useEffect, useState } from 'react';

const AutoTextarea = ({ onChange, placeholder, value, isPatternText = false, patternText, editing = false }) => {
    const textareaRef = useRef(null);
    const [textValue, setTextValue] = useState(value || '');

    const resizeTextarea = () => {
        const textarea = textareaRef.current;
        textarea.style.height = '35px';
        textarea.style.height = `${textarea.scrollHeight}px`;
    };

    useEffect(() => {
        resizeTextarea();
    }, [textValue, value]);

    useEffect(() => {
        if (editing && value) {
            setTextValue(value);
            setTimeout(resizeTextarea, 0)
        }
    }, [editing, value]);

    useEffect(() => {
        if (isPatternText) {
            setTextValue((prevText) => patternText + prevText);
        }
    }, [isPatternText]);


    const handleTextareaChange = (event) => {
        const newTextValue = event.target.value;

        const isStandardProcessing = !isPatternText || patternText === '';

        if (isStandardProcessing || newTextValue.startsWith(patternText)) {
            setTextValue(newTextValue);
        }
        if (onChange && (isStandardProcessing || newTextValue.startsWith(patternText))) {
            onChange(newTextValue);
        }
    };


    return (
        <textarea
            ref={textareaRef}
            onChange={handleTextareaChange}
            placeholder={placeholder}
            value={editing ? value || '' : textValue}
            style={{
                resize: 'none',
                overflowY: 'hidden',
                minHeight: '35px',
                maxHeight: '350px',
                height: 'auto',
            }}
        />
    );
};

export default AutoTextarea;
