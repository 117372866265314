import React, { useState, useEffect } from 'react';
import Button from '../../../../components/common/Button/button';
import { Label } from '../../../../components/common/Input/input.style';
import ModalWrap from '../../../../components/common/ModalNew/index';
import { Cancel } from '../Tasks/Modal/style';
import Input from '../../../../components/common/Input';
import CalendarCustom from '../../../../components/common/Calendar';
import { useForm } from 'react-hook-form';
import { Controller } from 'react-hook-form'
import { Divider } from 'antd';
import { Row } from './style';
import moment from 'moment';
import FileList from '../Tasks/Modal/FileList';
import { useStore } from '../../../../mobx-store/context';
import { Select } from 'antd'
import { checkRole } from '../../../../helpers/helpers'
import { toJS } from 'mobx';

const DeficiencyModal = ({
  editMode,
  defaultValue,
  type,
  onClose,
  onForceClose,
  onSubmit,
  onSubmitDeficiency,
  showCancel = true,
  isLicenseSource = true,
  licenseCompanyId,
  isRenewalsReporting = false,
}) => {
  const [dateValue, setDateValue] = useState(editMode ? defaultValue : '')
  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });
  const store = useStore();
  const [deficiencyFiles, setDeficiencyFiles] = useState([]);

  const [tasks, setTasks] = useState(editMode ? [] : [
    { owner: '', dueDate: '', description: '' },
  ]);

  const handleAddTask = () => {
    setTasks([...tasks, { owner: '', dueDate: '', description: '' }]);
  };

  const handleTaskChange = (index, field, value) => {
    const updatedTasks = tasks.map((task, i) =>
      i === index ? { ...task, [field]: value } : task
    );
    setTasks(updatedTasks);
  };

  const handleFileChange = (e) => {
    setDeficiencyFiles([...deficiencyFiles, [...e.target.files]])
  };

  const deleteFile = (name) => {
    const filtered = deficiencyFiles.filter((item) => Array.isArray(item) ? item[0].name !== name : item.file_name !== name)
    setDeficiencyFiles(filtered)
  }

  const deficiencyid = store?.currentDeficiencyBlock ? store?.currentDeficiencyBlock?.id : null;

  const handleDeficiencySubmit = (data, tasks, licenseCompanyId, deficiencyFiles) => {
    onSubmitDeficiency(data, tasks, licenseCompanyId, deficiencyFiles, type, editMode, deficiencyid);
    onForceClose()
  };
  const hasRequiredFields = watch('deficiency_date') && watch('deficiency_due_date') && watch('subject') && watch('description');
  const hasValidTasks = tasks.length > 0 ? tasks.every(task => task.owner && task.dueDate && task.description) : true;

  const isFormValid = hasRequiredFields && (editMode ? hasValidTasks : tasks.length > 0 && hasValidTasks);

  const updateDateFormate = (date) => {
    return date ? moment(date).format('YYYY-MM-DD') : '';
  }

  useEffect(() => {
    if (editMode && (type === 'ia_deficiency' || type === 'deficiency') && isLicenseSource) {
      const deficiencyData = toJS(store.currentDeficiencyBlock);
      reset({
        deficiency_date: deficiencyData.deficiency_date || '',
        deficiency_due_date: deficiencyData.due_date || '',
        subject: deficiencyData.subject || '',
        description: deficiencyData.description || '',
      });

      setDeficiencyFiles(toJS(deficiencyData.files) || []);
    }
  }, [editMode, reset, store.currentDeficiencyBlock]);

  return (
    <ModalWrap styleWrap={{ width: isLicenseSource && (type === 'ia_deficiency' || type === 'deficiency') ? '35%' : '25%' }} onClose={onClose} id={'deficiencyModal'}>
      <Label>
        {isLicenseSource && (type === 'ia_deficiency' || type === 'deficiency')
          ? editMode ? 'Edit Deficiency' : "Add Deficiency"
          : type === 'deficiency' || type === 'ia_deficiency'
            ? `${editMode ? 'Edit' : 'Enter'} Deficiency Due Date`
            : `${editMode ? 'Edit' : 'Enter'} Date Submitted`}
      </Label>

      {isLicenseSource && (type === 'ia_deficiency' || type === 'deficiency') ? (
        <div style={{ maxHeight: '55vh', overflowY: 'auto', paddingRight: '10px', marginTop: 20, height: editMode ? '42vh' : 'auto' }}>
          <Row >
            <Controller
              control={control}
              name={'deficiency_date'}
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <CalendarCustom
                  handleChange={(date) => setValue('deficiency_date', date)}
                  incomeValue={editMode && store.currentDeficiencyBlock.deficiency_date}
                  placeholder="Select Date"
                  label={'Deficiency Date'}
                  required={true}
                  styleDropdown={{ right: 'auto', left: 5, top: 50 }}

                />)}
            />
            <Controller
              control={control}
              name={'deficiency_due_date'}
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <CalendarCustom
                  handleChange={(date) => setValue('deficiency_due_date', updateDateFormate(date))}
                  incomeValue={editMode && store.currentDeficiencyBlock.due_date}
                  placeholder="Select Due Date"
                  label={'Deficiency Due Date'}
                  required={true}
                  styleDropdown={{ left: 'auto', right: 0, top: 50 }}
                />)}
            />
          </Row>
          <Input
            onChange={(e) => setValue('subject', e.target.value)}
            defaultValue={editMode && store.currentDeficiencyBlock.subject}
            style={{ marginBottom: 10 }}
            control={control}
            name="subject"
            label="Deficiency Subject"
            smallLabel
            type="text"
            validation={{ required: true }}
          />
          <Input
            onChange={(e) => setValue('description', e.target.value)}
            style={{ marginBottom: 10 }}
            defaultValue={editMode && store.currentDeficiencyBlock.subject}
            control={control}
            name="description"
            label="Deficiency Description"
            smallLabel
            type="textarea"
            validation={{ required: true }}
          />
          <Input
            control={control}
            name='file'
            type="file"
            label="Attach Deficiency Document(s):"
            smallLabel
            handleChange={handleFileChange}
          />
          <FileList temporaryFiles={deficiencyFiles} setTemporaryFiles={setDeficiencyFiles} deleteFile={deleteFile} isLicenseSource={isLicenseSource} isRenewalsReporting={isRenewalsReporting} isReportSource={false} />
          {
            <>
              <Divider />
              {tasks.map((task, index) => (
                <div key={index} style={{ marginBottom: '20px' }}>
                  <Row >
                    <Input
                      control={control}
                      name={`task_owner_${index}`}
                      label="Deficiency Task Owner"
                      type="select"
                      customOptions={
                        store.members
                          .filter((item) => {
                            return (
                              item.have_access_to_license_source &&
                              checkRole(
                                [item.license_source_role],
                                ['admin', 'license_owner',]
                              )
                            )
                          })
                          .map((item) => (
                            <Select.Option value={item.id} key={item.id}>
                              {item.first_name + ' ' + item.last_name}
                            </Select.Option>
                          ))
                      }
                      getPopupContainer={true}
                      smallLabel
                      selectVars={{ value: 0, name: 1 }}
                      value={task.owner}
                      validation={{ required: true }}
                      handleChange={(value) => handleTaskChange(index, 'owner', value)}
                    />
                    <CalendarCustom
                      handleChange={(date) => handleTaskChange(index, 'dueDate', date)}
                      incomeValue={task.dueDate}
                      placeholder="Task Due Date"
                      required={true}
                      styleDropdown={{ left: 'auto', right: 5, top: -375 }}
                    />
                  </Row>
                  <Input
                    control={control}
                    name={`task_description_${index}`}
                    label="Deficiency Task Description"
                    type="text"
                    smallLabel
                    validation={{ required: true }}
                    // value={task.description}
                    handleChange={(e) => handleTaskChange(index, 'description', e.target.value)}
                  />
                  {tasks.length > 1 && <Divider />}
                </div>
              ))}
              <Button text="Add Another Task" onClick={handleAddTask} style={{ marginTop: '10px' }} />
            </>
          }
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 30, flexDirection: 'column' }}>
          <CalendarCustom
            handleChange={(value) => setDateValue(value)}
            noLimit={type === 'deficiency' || type === 'ia_deficiency' ? false : true}
            incomeValue={dateValue || ''}
            styleDropdown={{ left: 'auto', right: 0, top: 50 }}
            placeholder={'Select Date'}
            label={
              type === 'deficiency' || type === 'ia_deficiency'
                ? 'Enter Deficiency Due Date'
                : 'Enter Date Submitted'
            }
          />
        </div>
      )}

      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
        {showCancel && <Cancel onClick={onClose}>Cancel</Cancel>}
        {isLicenseSource && (type === 'ia_deficiency' || type === 'deficiency') ?
          <Button
            text={editMode ? 'Save' : "Create Deficiency"}
            onClick={handleSubmit(data => handleDeficiencySubmit(data, tasks, licenseCompanyId, deficiencyFiles))}
            style={{ marginLeft: 'auto' }}
            disabled={!isFormValid}
          />
          : <Button
            text={editMode ? 'Edit' : 'Close'}
            style={{ marginLeft: 'auto' }}
            onClick={() => onSubmit(dateValue)}
            disabled={!dateValue}
          />
        }
      </div>
    </ModalWrap>
  );
};

export default DeficiencyModal;