import React, { useState } from 'react'
import { Block, Button, Item, MoreInfoCover, Updated } from './moreInfo.style'
import Moment from 'react-moment'
import { getDate, getDescription } from '../../../../helpers/helpers'
import { useStore } from '../../../../mobx-store/context'
import { formatCurrency } from '../../../AdminPanel/helpers'

const MoreInfo = ({ data, isReportSource = false, isLicenseSource = false, noHide = false, determinationSelected, isRenewalsReporting, isInitialApplication, params }) => {
  const [visible, setVisible] = useState(false);

  const store = useStore()
  const trackingMode = store?.company[0]?.compliance_source_mode
  const showModeBlocks = isReportSource
    ? true
    : trackingMode === 'compliance_source' ? true : false

  const ReportBlock = () => {
    return (
      <>
        <Item>
          <span>Report Due Date</span>
          {data?.report?.report_due_date_is_variable ? 'Variable' : data?.report?.report_due_date_is_upon_request ? 'Upon Request: See Notes' : getDate(data?.report?.report_due_date)}
        </Item>
        <Item>
          <span> Reporting Entity</span>
          <div dangerouslySetInnerHTML={{
            __html: getDescription(store.reportingEntities, data?.report?.reporting_entity)?.split(',')?.join('<br />')
          }}
          />
        </Item>
        <Item>
          <span>Report To</span>
          {data?.report?.report_to}
        </Item>
      </>)
  }

  const ComplianceBlock = () => {
    return (
      <>
        <Item>
          <span>Adopted Date</span>
          <Moment format={"MM/DD/YYYY"}>{data?.adopted_date}</Moment>
        </Item>
        <Item>
          <span>Effective Date</span>
          {getDate(data?.effective_date)}
        </Item>
        <Item>
          <span>Compliance Date</span>
          {getDate(data?.compliance_date)}
        </Item>
        <Item>
          <span>End Date</span>
          {data?.end_date}
        </Item>
      </>)
  }

  const LicenceBlock = () => {
    return (
      <>
        <Item>
          <span>License Term</span>
          {isRenewalsReporting ? data?.license?.license_term : store?.currentDetermination?.license_determination?.license_term}
        </Item>
        <Item>
          <span>Filing Fee</span>
          {isRenewalsReporting
            ? formatCurrency(data?.license?.fees_renewals_reporting)
            : (isInitialApplication || params.tabName === 'ia_closed')
              ? formatCurrency(store?.currentDetermination?.license_determination?.fees_initial_application)
              : formatCurrency(store?.currentDetermination?.license_determination?.license_fee)}
        </Item>
        {(isInitialApplication || params.tabName === 'ia_closed' || isRenewalsReporting) &&
          <Item>
            <span>Submission Method</span>
            {isRenewalsReporting
              ? data?.license?.submission_method_renewals
              || 'No Data'
              : (isInitialApplication || params.tabName === 'ia_closed')
                ? store?.currentDetermination?.license_determination?.submission_method_initial_application || 'NoData'
                : ''}
          </Item>
        }
        <Item>
          <span>Regulatory Agency</span>
          {isRenewalsReporting ? data?.license?.regulatory_agency : store?.currentDetermination?.license_determination?.regulatory_agency}
        </Item>
      </>)
  }

  return (
    trackingMode !== 'compliance_source_ultra_lite' && (isLicenseSource ? determinationSelected ? true : false : true) &&
    <>
      <MoreInfoCover isReportSource={isReportSource}>
        {!noHide &&
          <Button onClick={() => setVisible(!visible)}>
            {visible ? "<< Show Less Information" : "Show More Information >>"}
          </Button>
        }
      </MoreInfoCover>
      <Block visible={visible || noHide}>
        {isLicenseSource ? <LicenceBlock /> : isReportSource ? <ReportBlock /> : <ComplianceBlock />}
      </Block>
    </>
  )
}

export default MoreInfo;
