import React from 'react'
import deleteIcon from '../../../../assets/images/close_green.svg'
import { Label } from '../../../../components/common/Input/input.style'
import { getDescription } from '../../../../helpers/helpers'
import Modal from '../Modal'
import { CurrentJurs, SelectedJurisdictions } from './style'

const Jurisdictions = ({
  store,
  control,
  onSave,
  selectedJurisdictions,
  onDelete,
  error,
  visible,
  toggleModal,
  paidJurs,
}) => {
  return (
    <>
      {visible && (
        <Modal
          store={store}
          control={control}
          onSave={onSave}
          onClose={() => toggleModal(false)}
          selectedJurisdictions={selectedJurisdictions}
          paidJurs={paidJurs}
          error={error}
          isPrimary
        />
      )}

      <div>
        <Label required>
          1. Select Primary Jurisdiction(s) <span>*</span>
        </Label>
        <Label style={{ fontSize: 12 }}>
          Primary jurisdictions will apply to all selected guides unless
          customized.
        </Label>
        {paidJurs.length > 0 && (
          <CurrentJurs>
            Current Jurisdictions:{' '}
            {paidJurs
              .map((item) => getDescription(store.resourceJurisdictions, item, 'code'))
              .join(', ')}
          </CurrentJurs>
        )}
        <SelectedJurisdictions
          onClick={(e) => {
            if (e.target.tagName !== 'IMG') toggleModal(true)
          }}
        >
          {!selectedJurisdictions.length && <span>Select All That Apply</span>}
          {selectedJurisdictions
            .filter((item) => !paidJurs.includes(item))
            .map((item) => (
              <div key={item} style={{ whiteSpace: 'nowrap' }}>
                {getDescription(store.resourceJurisdictions, item, 'code')}
                <img
                  src={deleteIcon}
                  alt='delete-icon'
                  onClick={() => onDelete(item)}
                />
              </div>
            ))}
        </SelectedJurisdictions>
      </div>
    </>
  )
}

export default Jurisdictions
