import React from 'react'
import { Mentions } from 'antd'
import { StyledMentions } from '../input.style'

const InputMentions = ({
  onChange,
  handleChange,
  defaultValue,
  value,
  options,
  placeholder,
}) => {
  return <Mentions
    placeholder={placeholder}
    onChange={(value) => {
      onChange(value)
      handleChange && handleChange(value)
    }}
    getPopupContainer={(triggerNode) => triggerNode.parentNode.parentNode.parentNode.parentNode}
    autoSize
    defaultValue={defaultValue ? '@' + defaultValue : ''}
    value={value || ''}
  >
    {options}
  </Mentions>
}

export default InputMentions
