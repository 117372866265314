import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Spin } from 'antd'
import Disclaimer from '../../../components/common/Disclaimer'
import TopLine from '../../../components/TopLine/topLine'
import { getDescription } from '../../../helpers/helpers'
import { useStore } from '../../../mobx-store/context'
import Header from '../components/Header'
import { Text } from '../components/style'
import Filters from '../Resource/Filters'
import { Wrap } from '../Resource/style'
import { Container, Loader } from './style'
import Table from './Table'
import ChangePasswordModal from '../../Auth/ChangePassowordModal'
import TCModal from '../../Tracking/Modal/tcModal'

const ResourceTable = observer(() => {
  const params = useParams()
  const store = useStore()
  const history = useHistory()
  const [active, setActive] = useState(0)
  const [expiredPassword, setExpiredPassword] = useState(false)
  const [showDisclaimer, setShowDisclaimer] = useState(false)

  const isGuides = params.type === 'guides'

  const jurs = store.user?.[0]?.resource_jurisdictions.map((item) => ({
    id: item,
    name: getDescription(store.resourceJurisdictions, item),
  }))

  const resources = store.resources?.filter((item) =>
    store.user?.[0]?.resource_guides.includes(item.guide_name_id)
  )

  const handleDisclaimerClose = () => {
    setShowDisclaimer(prev => ({ ...prev, fadeOut: true }));
    setTimeout(() => {
      setShowDisclaimer({ show: false, fadeOut: false });
      sessionStorage.setItem('showDisclaimer', 'false');
    }, 500);
  }

  useEffect(() => {
    setExpiredPassword(localStorage.getItem('is_time_to_change_password') === 'true')
  }, [])

  useEffect(() => {
    const show = sessionStorage.getItem('showDisclaimer') === 'true';
    setShowDisclaimer({ show: show, fadeOut: !show });
  }, []);


  useEffect(() => {
    if (!store.resourceTariffInfo) store.getResourceTariffInfo()
  }, [])

  useEffect(() => {
    if (!store.company?.[0].have_access_to_resource)
      history.push('/edit-profile')
  }, [store.resourceTariffInfo])

  useEffect(() => {
    if (!store.jurs?.length) store.loadSelect('companyOptions')
    if (!store.resources?.length) store.getResourceTopic()
    if (!store.resourceFilters) store.getResourceFilters()
  }, [])



  return (
    <>
      <TopLine />
      <Wrap>
        <Container>
          {
            !store.user[0].is_accepted_terms_and_conditions &&
            <TCModal />
          }
          {store.user[0].should_see_pbm_updates && <TCModal type={'announcement'} />}
          <div style={{ position: 'sticky', top: 90, zIndex: 98, background: '#FFF' }}>
            <Header onTabChange={(key) => setActive(key)} active={active} />
            {expiredPassword && <ChangePasswordModal onClose={() => setExpiredPassword(false)} expiredPassword />}
            {jurs && resources && (
              <Filters tabKey={active} jurs={jurs} resources={resources} />
            )}
            <Text>Select a row to view more details</Text>
          </div>
          {isGuides
            ? store.resourcesCurrentTopic ? <Table active={active} /> :
              <Loader>
                <Spin style={{ margin: "0 auto" }} />
              </Loader>
            : store.resourcesTable?.length > 0 ? <Table active={active} /> :
              <Loader>
                <Spin style={{ margin: "0 auto" }} />
              </Loader>
          }
        </Container>
      </Wrap>
      {showDisclaimer.show && <Disclaimer onClose={handleDisclaimerClose} fadeOut={showDisclaimer.fadeOut} />}
    </>
  )
})

export default ResourceTable
