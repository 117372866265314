import React, { useMemo, useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { useStore } from '../../../../mobx-store/context';
import { DatePicker } from 'antd';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

const { RangePicker } = DatePicker;

const InputDateRange = ({
  name,
  visible,
  onChange,
  reset,
  handleChange,
  disabled = false,
  getPopupContainer = false,
  value,
}) => {
  const store = useStore();
  const params = useParams();
  const rangePickerRef = useRef(null);
  const [selectedDates, setSelectedDates] = useState([]);
  const [isStartDateManuallyEntered, setIsStartDateManuallyEntered] = useState(false);
  const [isEndDateManuallyEntered, setIsEndDateManuallyEntered] = useState(false);
  const [inputValues, setInputValues] = useState({ start: "", end: "" });

  const localValue = useMemo(() => {
    if (value) {
      const start = moment(value?.split('±')[0]);
      const end = moment(value?.split('±')[1]);
      return [start.isValid() ? start : null, end.isValid() ? end : null];
    }
    return selectedDates;
  }, [value, selectedDates]);

  const parentNode = () => {
    if (getPopupContainer) {
      return (trigger) => trigger.parentNode.parentNode.parentNode;
    }
    return false;
  };

  const handleReset = (allowHandleChange = false) => {
    const newSearchObj = params.tabName === 'myTasks' ? { ...store.searchableObjectTasks } : { ...store.searchableObject };

    delete newSearchObj.adopted_date_after;
    delete newSearchObj.adopted_date_before;
    delete newSearchObj.primary_due_date_before;
    delete newSearchObj.primary_due_date_after;
    delete newSearchObj.expiration_date_before;
    delete newSearchObj.expiration_date_after;
    delete newSearchObj.internal_due_date_before;
    delete newSearchObj.internal_due_date_after;
    delete newSearchObj.compliance_date_before;
    delete newSearchObj.compliance_date_after;

    params.tabName === 'myTasks'
      ? store.setSingleValue('searchableObjectTasks', newSearchObj)
      : store.setSingleValue('searchableObject', newSearchObj);

    reset(name);
    if (handleChange && allowHandleChange) handleChange();

    setSelectedDates([]);
    setIsStartDateManuallyEntered(false);
    setIsEndDateManuallyEntered(false);
    setInputValues({ start: "", end: "" });
  };

  useEffect(() => {
    if (value === null || value === undefined) {
      handleReset();
    }
  }, [value]);

  useEffect(() => {
    if (!rangePickerRef.current) return;

    const inputs = rangePickerRef.current.querySelectorAll(".ant-picker-input input");
    if (inputs.length < 2) return;

    const observer = new MutationObserver(() => {
      setInputValues((prev) => ({
        start: inputs[0]?.value || prev.start,
        end: inputs[1]?.value || prev.end,
      }));

      if (inputs[0]?.value) setIsStartDateManuallyEntered(true);
      if (inputs[1]?.value) setIsEndDateManuallyEntered(true);
    });

    observer.observe(inputs[0], { attributes: true, childList: true, subtree: true, characterData: true });
    observer.observe(inputs[1], { attributes: true, childList: true, subtree: true, characterData: true });

    return () => observer.disconnect();
  }, [visible]);

  return (
    <div style={{ width: '100%' }} ref={rangePickerRef}>
      <RangePicker
        disabled={disabled}
        format="MM/DD/YYYY"
        disabledDate={(d) =>
          !d || d.isAfter('2030-12-31') || d.isSameOrBefore('2022-01-01')
        }
        value={localValue}
        open={visible}
        dropdownClassName={'dateRangePicker'}
        placeholder={['MM/DD/YYYY Start Date', 'MM/DD/YYYY End Date']}
        getPopupContainer={parentNode()}
        bordered={false}
        allowEmpty={[true, true]}
        onCalendarChange={(dates) => {
          if (dates && dates.length === 1) {
            setSelectedDates(dates);
          } else if (dates && dates.length === 2) {
            setSelectedDates(dates);
          }
        }}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            if (isStartDateManuallyEntered && inputValues.start && !inputValues.end) {
              // При ручном вводе первой даты сохраняем её в `selectedDates`, но не отправляем запрос
              setSelectedDates([moment(inputValues.start, "MM/DD/YYYY")]);
              setIsStartDateManuallyEntered(false);
              return;
            }

            if (isEndDateManuallyEntered && inputValues.start && inputValues.end) {
              // Если введены обе даты, обновляем `selectedDates` и отправляем запрос
              setSelectedDates([moment(inputValues.start, "MM/DD/YYYY"), moment(inputValues.end, "MM/DD/YYYY")]);
              onChange(`${inputValues.start}±${inputValues.end}`);
              if (handleChange) handleChange(`${inputValues.start}±${inputValues.end}`);
              setIsEndDateManuallyEntered(false);
            }
          }

          // if (!isOpen && selectedDates.length === 1) {
          //   handleReset(true);
          // }
        }}
        onChange={(dates, dateStrings) => {
          if (!dates || dates.length === 0) {
            handleReset(true);
          } else if (dates.length === 2) {
            if (dateStrings[1] !== '') {
              setSelectedDates([moment(dateStrings[0], "MM/DD/YYYY"), moment(dateStrings[1], "MM/DD/YYYY")]);
              onChange(`${dateStrings[0]}±${dateStrings[1]}`);
              if (handleChange) handleChange(`${dateStrings[0]}±${dateStrings[1]}`);
            }
          }
        }}
      />
    </div>
  );
};

export default InputDateRange;