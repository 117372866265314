import React, { useEffect, useState } from 'react'
import { WrapEditor, Wrap, Toolbar, ToolButton, TextEditorWrap } from '../Details/style'
import { Editor, EditorState, RichUtils, convertFromHTML, ContentState, Modifier } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import boldIcon from '../../../../assets/images/bold.svg'
import italicIcon from '../../../../assets/images/italic.svg'
import underlineIcon from '../../../../assets/images/underline.svg'
import '../Details/DraftStyleDefault.css'
import 'draft-js/dist/Draft.css'

const styleMap = {
  BOLD: {
    fontFamily: 'var(--bold) !important',
    fontWeight: '900 !important',

  },
  ITALIC: { fontStyle: 'italic' },
  UNDERLINE: { textDecoration: 'underline' }
};
const MAX_LENGTH = 1200;

const TextEditor = ({ updateDetails, placeholderText = '', setIsEmpty, initialText = '' }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [errorMessage, setErrorMessage] = useState('');
  const [active, setActive] = useState({ BOLD: false, ITALIC: false, UNDERLINE: false });

  const getCurrentLength = (state) => state.getCurrentContent().getPlainText().length;

  const handleBeforeInput = (chars, editorState) => {
    const currentLength = editorState.getCurrentContent().getPlainText().length;
    if (currentLength + chars.length > MAX_LENGTH) {
      setErrorMessage(`Character limit of ${MAX_LENGTH} exceeded!`);
      return 'handled';
    }
    setErrorMessage('');
    return 'not-handled';
  };

  const handlePastedText = (pastedText, html, editorState) => {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const currentLength = getCurrentLength(editorState);

    if (currentLength >= MAX_LENGTH) {
      setErrorMessage(`Character limit of ${MAX_LENGTH} exceeded!`);
      return 'handled';
    }


    const availableSpace =
      MAX_LENGTH - currentLength + (selection.isCollapsed() ? 0 : selection.getEndOffset() - selection.getStartOffset());

    const truncatedText = pastedText.substring(0, availableSpace);

    const newContentState = Modifier.replaceText(contentState, selection, truncatedText);

    const newEditorState = EditorState.push(editorState, newContentState, 'insert-characters');

    setEditorState(newEditorState);
    setErrorMessage(availableSpace < pastedText.length ? `Character limit of ${MAX_LENGTH} exceeded!` : '');

    return 'handled';
  };

  const checkIsOnlySpaces = () => {
    const content = editorState.getCurrentContent();
    return content.hasText() && content.getPlainText().trim().length === 0;
  };

  const isEmpty = !editorState.getCurrentContent().hasText() || checkIsOnlySpaces();


  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const transformText = (e, type) => {
    e.preventDefault();
    setActive({ ...active, [type]: !active[type] });
    setEditorState(RichUtils.toggleInlineStyle(editorState, type));
  };
  useEffect(() => {
    if (initialText) {
      const blocksFromHTML = convertFromHTML(initialText);
      const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, []);

  useEffect(() => {
    setIsEmpty(isEmpty);
  }, [isEmpty]);


  useEffect(() => {
    if (isEmpty) setActive({
      BOLD: false,
      ITALIC: false,
      UNDERLINE: false,
    })
  }, [isEmpty])

  useEffect(() => {
    if (isEmpty) setIsEmpty(true)
    else setIsEmpty(false)

  }, [isEmpty])

  return (
    <TextEditorWrap>
      <WrapEditor style={{ overflow: 'scroll' }}>
        <Toolbar>
          <ToolButton onMouseDown={(e) => transformText(e, 'BOLD')} active={active['BOLD']}>
            <img src={boldIcon} alt="bold-icon" />
          </ToolButton>
          <ToolButton onMouseDown={(e) => transformText(e, 'ITALIC')} active={active['ITALIC']}>
            <img src={italicIcon} alt="italic-icon" />
          </ToolButton>
          <ToolButton onMouseDown={(e) => transformText(e, 'UNDERLINE')} active={active['UNDERLINE']}>
            <img src={underlineIcon} alt="underline-icon" />
          </ToolButton>
        </Toolbar>
        <Editor
          editorState={editorState}
          handleBeforeInput={handleBeforeInput}
          handlePastedText={handlePastedText}
          onChange={(editorState) => {
            setEditorState(editorState);
            updateDetails(stateToHTML(editorState.getCurrentContent()));
            const currentLength = editorState.getCurrentContent().getPlainText().length;
            if (currentLength < MAX_LENGTH) {
              setErrorMessage('');
            }
          }}
          handleKeyCommand={handleKeyCommand}
          placeholder={placeholderText || 'Type your text...'}
          customStyleMap={{

            ...styleMap
          }}
        />
        {errorMessage && <p style={{ color: 'red', fontSize: '14px' }}>{errorMessage}</p>}
      </WrapEditor>
    </TextEditorWrap>
  );
};

export default TextEditor;