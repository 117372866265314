import React, { useState } from 'react';
import deleteFileIcon from '../../../../../../assets/images/orange_bin.svg';
import Button from '../../../../../../components/common/Button/button';
import ButtonOrange from '../../../../../../components/common/ButtonOrange';
import { Container, Input } from './style';
import { api } from '../../../../../../api';
import { useParams } from 'react-router-dom/';

const FileList = ({
  temporaryFiles,
  setTemporaryFiles,
  deleteFile,
  isReportSource,
  isLicenseSource,
  isRenewalsReporting,
  isActionsTabs = false,
  actionTabName = '',
  isModalCompleted = false,
  isTaskFile = false,
  downloadDoc = null,
  onLoad = null,
  disabled = false,
}) => {
  const [renamingFileIndex, setRenamingFileIndex] = useState(null);
  const [newFileName, setNewFileName] = useState('');

  const params = useParams();
  const handleRenameClick = (index) => {
    setRenamingFileIndex(index);

    const fileName = Array.isArray(temporaryFiles[index])
      ? temporaryFiles[index][0].name
      : temporaryFiles[index].file_name || temporaryFiles[index].name

    const nameWithoutExtension = fileName.substring(0, fileName.lastIndexOf('.'));
    setNewFileName(nameWithoutExtension);
  };

  const handleSaveClick = async (index) => {
    const fileItem = temporaryFiles[index];
    const extension = Array.isArray(fileItem)
      ? fileItem[0].name.substring(fileItem[0].name.lastIndexOf('.'))
      : fileItem.file_name ? fileItem.file_name.substring(fileItem.file_name.lastIndexOf('.')) : fileItem.name.substring(fileItem.name.lastIndexOf('.')) // Для существующих файлов

    const updatedFileName = newFileName + extension;

    if (Array.isArray(fileItem)) {
      const updatedFiles = [...temporaryFiles];
      const file = fileItem[0];
      const updatedFile = new File([file], updatedFileName, { type: file.type });
      updatedFiles[index] = [updatedFile, ...fileItem.slice(1)];
      setTemporaryFiles(updatedFiles);
    } else {
      const url = `/file/${fileItem.id}/rename/`;
      let model;
      if (isReportSource) {
        model = 'ReportCompanyFile';
      } else if (isLicenseSource) {
        if (params.tabName === 'held') {
          if (isActionsTabs) {
            if (actionTabName === 'admin_actions') {
              model = 'AdminActionAttachment';
            } else if (actionTabName === 'market_conduct_exams_held') {
              model = 'MarketConductExamsAttachment';
            } else if (actionTabName === 'misc_held') {
              model = 'MiscAttachment';
            } else if (actionTabName === 'notice_of_change_held') {
              model = 'NoticeOfChangeAttachment';
            }
          }
          else {
            model = 'LicenseCompanyHeldCertificates'
          }
        }
        else {
          model = isRenewalsReporting ? 'RenewalLicenseFile' : 'LicenseCompanyFile';
        }
      }
      else {
        model = 'LegislativeFile';
      }

      const obj = {
        model,
        new_name: newFileName,
      };

      try {

        await api(url, obj, 'PATCH').then((data) => {
          onLoad && onLoad();
        });


        const updatedFiles = [...temporaryFiles];
        updatedFiles[index].file_name = updatedFileName;
        setTemporaryFiles(updatedFiles);
      } catch (error) {
        console.error('Error renaming file:', error);
      }
    }

    setRenamingFileIndex(null);
    setNewFileName('');
  };

  const handleFileNameChange = (e) => {
    setNewFileName(e.target.value);
  };

  const handleDownload = (item) => {
    if (!downloadDoc) return;

    if (Array.isArray(item)) {

      downloadDoc(item[0].id, item[0].name);
    } else {
      downloadDoc(item.id, item.file_name || item.name);
    }
  };

  return (
    <div>
      {temporaryFiles && temporaryFiles.map((item, index) => (
        <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
          {renamingFileIndex === index ? (
            <Container>
              <Input
                type="text"
                value={newFileName}
                onChange={handleFileNameChange}
              />
              <Button
                onClick={() => handleSaveClick(index)}
                text={'Rename'}
                style={{ marginRight: 5 }}
                type={'main'}
                small
                disabled={!newFileName.trim()}
              />
            </Container>
          ) : (
            <>
              <ButtonOrange text={Array.isArray(item) ? item[0].name : item.file_name || item.name}
                onClick={() => handleDownload(item)} />
              <img
                src={deleteFileIcon}
                alt={'delete-icon'}
                onClick={isModalCompleted
                  ? () => deleteFile(item.name, item.id)
                  : isTaskFile ? () => deleteFile(item.name, item.id)
                    : () => deleteFile(Array.isArray(item) ? item[0].name : item.file_name, item)
                }
                style={{ marginLeft: 5, cursor: 'pointer' }}
              />
              {!disabled && (
                <ButtonOrange
                  onClick={() => handleRenameClick(index)}
                  text={'Rename'}
                  style={{ marginLeft: 10 }}
                />
              )}
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default FileList;