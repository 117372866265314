import React, { useState } from 'react'
import Button from '../../Button/button'
import { File } from '../input.style'

const InputFiles = ({
  loading,
  handleChange,
  onBlur,
  placeholder,
  onChange,
  multiple,
  showFile,
  accept,
}) => {
  const [file, setFile] = useState('');
  const [inputKey, setInputKey] = useState(Date.now());

  return (
    <>
      <File>{showFile ? file : loading && file}</File>
      <input
        key={inputKey}
        type={'file'}
        onChange={(e) => {
          if (e.target.files[0]) {
            handleChange && handleChange(e);
            setFile(e.target.files[0].name);
            onChange(e);
            setInputKey(Date.now());
          }
        }}
        onBlur={onBlur}
        placeholder={placeholder}
        multiple={multiple}
        accept={accept}
      />
      <Button
        text={'Browse'}
        type={'main'}
        style={{ marginRight: '1em' }}
        small
        loading={loading}
      />
    </>
  );
};

export default InputFiles;