import styled from 'styled-components'

export const Item = styled('div')`
  font-size: 14px;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr;
  padding: 5px 0;
  border-bottom:${props => props.bordered ? '1px solid #C4CACC;' : 'none'}  

`
export const RowContainer = styled('div')`
  display: grid;
  grid-template-columns: 62% 38%;
  
`